import { Asset, TrackedTransaction } from '#types/index';
import { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ArrowPathIcon, BellAlertIcon } from '@heroicons/react/24/solid';
import { LearnMoreHelp } from '#components/InlineHelp/LearnMoreHelp';
import {
  updateTrackedTransaction,
  updateWalletListingToComplete,
} from '#lib/firestore';
import React from 'react';
import { trpcClient } from 'pages/_app';
import { VerifiedCollectionPopoverSmall } from '#components/Tooltip/VerifiedCollectionPopoverSmall';
import Link from 'next/link';
import cn from 'classnames';
import { Tooltip } from '@nextui-org/react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { TrackedTransaction as TrackedTransactionType } from '#types/index';
import { getTrackedTransactionsListener } from '#lib/firestore';
import { AssetImage } from '#components/Assets/AssetPage/AssetImage';
import { NextImage } from '#components/Assets/NextImage';
import { TxDisplay } from '#components/Offers/TxDisplay';

export interface TransactionActivityProps {
  userId: string;
  compact?: boolean;
  showAll?: boolean;
}

dayjs.extend(relativeTime);

export type TrackedTransactionsState = {
  loading: boolean;
  trackedTransactions: Map<string, TrackedTransactionType[]>;
  listener: Map<string, ReturnType<typeof getTrackedTransactionsListener>>;
  addListenerIfNotAlreadyAdded: (userId: string) => void;
  unsubscribe: (userId: string) => void;
};

// Create a Zustand Store to manage the state of the tracked transactions
export const useTrackedTransactionsStore = create<
  TrackedTransactionsState,
  [['zustand/devtools', TrackedTransactionsState]]
>(
  devtools<TrackedTransactionsState>(
    (set, get) => ({
      loading: true,
      trackedTransactions: new Map(),
      listener: new Map(),
      addListenerIfNotAlreadyAdded: (userId: string) => {
        const userListener = get().listener.get(userId);

        if (Boolean(userListener)) return;

        const l = getTrackedTransactionsListener(userId, (data) => {
          const updateTrackedTransaction = new Map(
            get().trackedTransactions
          ).set(userId, data);
          set((prev) => ({
            ...prev,
            loading: false,
            trackedTransactions: updateTrackedTransaction,
          }));
        });

        set((prev) => ({
          ...prev,
          listener: new Map(prev.listener).set(userId, l),
        }));
      },
      unsubscribe: (userId: string) => {
        const l = get().listener.get(userId);
        if (l) {
          l();
          set((prev) => {
            const newMap = new Map(prev.listener);
            newMap.delete(userId);

            return {
              ...prev,
              listener: newMap,
            };
          });
        }
      },
    }),
    {
      enabled: true,
      name: 'Tracked Transactions Store',
    }
  )
);

// Simple singleton function that looks at the useTrackedTransactionsStore::trackedTransactions
// and if the Transaction is not COMPLETED it will ask the server for its status
function maintainTransactionStatus() {
  const m = useTrackedTransactionsStore.getState().trackedTransactions;
  Array.from(m.entries()).forEach(([userId, transactions]) => {
    transactions.forEach((t) => {
      if (t.status !== 'COMPLETED') {
        trpcClient
          .query('track-transaction', {
            txHash: t.txId,
          })
          .then((res) => {
            if (!res) return;
            return Promise.all([
              !!t.asset?.assetId &&
                t.action == 'RELIST' &&
                updateWalletListingToComplete(userId, t.asset.assetId),
              updateTrackedTransaction(userId, t.txId, 'COMPLETED'),
            ]);
          });
      }
    });
  });
}

setInterval(() => maintainTransactionStatus(), 4000);

export const TransactionActivity: FC<TransactionActivityProps> = ({
  userId,
  compact = false,
  showAll = true,
}) => {
  const {
    addListenerIfNotAlreadyAdded,
    loading,
    trackedTransactions,
    unsubscribe,
  } = useTrackedTransactionsStore((state) => ({
    loading: state.loading,
    trackedTransactions: state.trackedTransactions.get(userId),
    addListenerIfNotAlreadyAdded: state.addListenerIfNotAlreadyAdded,
    unsubscribe: state.unsubscribe,
  }));

  // Use an effect to register the listener on mount and unsubscribe on unmount
  useEffect(() => {
    addListenerIfNotAlreadyAdded(userId);

    return () => {
      unsubscribe(userId);
    };
  }, [addListenerIfNotAlreadyAdded, unsubscribe, userId]);

  if (
    !loading &&
    (!trackedTransactions ||
      trackedTransactions.filter((t) => {
        return !!showAll ? true : t.status != 'COMPLETED';
      }).length < 1)
  ) {
    return (
      <div
        className={cn(
          'w-full  flex flex-col justify-center items-center bg-white/25 dark:bg-gray-700/25 rounded-lg gap-2',
          { 'min-h-[10rem]': !!compact },
          { 'min-h-[33rem]': !compact }
        )}
      >
        <BellAlertIcon
          className={cn(
            'text-gray-500',
            { 'w-10 h-10': !!compact },
            { 'w-24 h-24': !compact }
          )}
        />
        <h2
          className={cn(
            'font-bold dark:text-white',
            { 'text-lg': !!compact },
            { 'text-2xl': !compact }
          )}
        >
          Buy and sell on Dropspot.
        </h2>
        <p className="text-base font-light text-gray-500 max-w-md text-center mb-4">
          {showAll && (
            <span>Recent and complete transactions will show here.</span>
          )}
          {!showAll && <span>In progress transactions will show here.</span>}
        </p>
        {!compact && (
          <LearnMoreHelp
            description={'more about notifications'}
            link={
              'https://help.dropspot.io/en/articles/6692990-how-do-i-manage-my-notifications'
            }
          />
        )}
      </div>
    );
  }

  if (loading) {
    return (
      <div className="w-full min-h-[33rem] flex flex-col justify-center items-center bg-white/25 dark:bg-gray-700/25 rounded-lg gap-2">
        <BellAlertIcon className="w-24 h-24 text-gray-500" />
        <h2 className="text-2xl font-bold dark:text-white">Loading</h2>
      </div>
    );
  }
  return (
    <>
      <div className="w-full flex flex-col gap-2 justify-center lg:items-center overflow-scroll">
        <table className="table w-full min-w-full divide-y divide-gray-300 dark:divide-gray-700 dark:text-gray-200">
          {!compact && (
            <thead className="bg-gray-50 dark:bg-gray-900 dark:text-gray-300">
              <tr className="w-full">
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left  font-semibold text-gray-900 dark:text-gray-200"
                >
                  Asset
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left font-semibold text-gray-900 dark:text-gray-200"
                >
                  Action
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left font-semibold text-gray-900 dark:text-gray-200"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left font-semibold text-gray-900 dark:text-gray-200"
                >
                  Status
                </th>
              </tr>
            </thead>
          )}

          <tbody className="w-full">
            {trackedTransactions &&
              trackedTransactions
                .filter((t) => {
                  return !!showAll ? true : t.status != 'COMPLETED';
                })
                .splice(
                  0,
                  !compact
                    ? trackedTransactions.length
                    : Math.min(trackedTransactions.length, 2)
                )
                .map((tx, id) => (
                  <TrackedTransactionComp
                    key={`${tx.txId}.${
                      tx.asset?.id || tx.asset?.assetId || 'xx'
                    }#${id}`}
                    tx={tx}
                    compact={compact}
                  />
                ))}
          </tbody>
        </table>
        {!!compact && (trackedTransactions?.length || 0) > 3 && (
          <Link href={`/profile?tab=activity`}>
            <a className="text-gray-600 dark:text-gray-400 font-light text-sm w-max mx-auto">
              Show All
            </a>
          </Link>
        )}
      </div>
    </>
  );
};

export interface TransactionHoldingProps {
  tx: TrackedTransaction;
  compact: boolean;
}

type TransactionHoldingState = {
  isPolling: boolean;
  status: 'COMPLETED' | 'INPROGRESS' | 'ERROR';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  txnResult?: {
    id: string;
    block_no: number;
    tip: number;
    no_confirmations: number;
  };
};

export class TrackedTransactionComp extends React.Component<
  TransactionHoldingProps,
  TransactionHoldingState
> {
  state: TransactionHoldingState;
  poll: NodeJS.Timeout | undefined = undefined;
  _isMounted: boolean = false;

  constructor(props: TransactionHoldingProps) {
    super(props);

    this.state = {
      isPolling: false,
      status: 'INPROGRESS',
    };
  }

  componentWillUnmount(): void {
    if (this._isMounted) {
      this._isMounted = false;
      if (this.poll) {
        clearTimeout(this.poll);
        this.poll = undefined;
      }
      this.setState({ isPolling: false });
    }
  }

  trackTransaction() {
    console.log('Tracking transaction', this.state.status, this.props.tx.txId);
    if (this.state.status === 'COMPLETED') {
      return;
    }
    trpcClient
      .query('track-transaction', {
        txHash: this.props.tx.txId,
      })
      .then((response) => {
        if (!response) {
          this.runPolling();
          return;
        }

        this.setState({ txnResult: response, status: 'COMPLETED' });

        // Firebse update record
        return updateTrackedTransaction(
          this.props.tx.userId,
          this.props.tx.txId,
          'COMPLETED'
        );
      })
      .catch((err) => this.setState({ status: 'ERROR', error: err }));
  }

  runPolling() {
    this.poll = setTimeout(() => this.trackTransaction(), 5000);
  }

  componentDidMount(): void {
    this._isMounted = true;
    // Start Polling
    //put condition around this. if status is not completed, then poll
    if (this.state.status !== 'COMPLETED') {
      this.trackTransaction();
    }
  }

  render() {
    return this.props.compact ? (
      <tr className="w-full">
        <td className="w-full  pl-1 pr-1 py-3 text-gray-800 dark:text-gray-200">
          <div className="flex flex-row-wrap sm:gap-4 w-full justify-between items-center">
            <div className="flex flex-row gap-4">
              <Tooltip
                content={`${dayjs(
                  this.props.tx.createdDate
                ).fromNow()} | ${dayjs(this.props.tx.createdDate).format(
                  'HH:mm a |  DD-MMM-YYYY'
                )}`}
              >
                <span className="flex flex-col text-sm">
                  {this.props.tx.action == 'OFFER' ||
                  this.props.tx.action == 'OFFER-CANCEL' ||
                  this.props.tx.action == 'ACCEPT-OFFER' ||
                  this.props.tx.action == 'OFFER-ACCEPTED' ? (
                    <OfferDetails
                      trackedTransaction={this.props.tx}
                      compact={this.props.compact}
                    />
                  ) : (
                    <div className="flex flex-col">
                      <img
                        src={this.props.tx.asset?.thumbnailUrl}
                        alt={''}
                        className="w-12 h-12 rounded-lg shadow-lg object-cover"
                      />
                      <Link href={`/nft/${this.props.tx.asset?.id}`}>
                        <a className="font-medium text-sm sm:text-base truncate max-w-[198px] sm:max-w-[270px]">
                          {this.props.tx.asset?.title}
                        </a>
                      </Link>
                      {this.props.tx.action === 'SOLD' && (
                        <span className="font-normal flex flex-col lg:flex-row gap-2  ">
                          <span className=" dark:text-green-500 text-green-600">
                            {this.props.tx.status != 'COMPLETED'
                              ? 'Being sold'
                              : 'Sold'}
                          </span>{' '}
                          for {(this.props.tx.price || 0) / 1_000_000} ADA
                        </span>
                      )}
                      {this.props.tx.action === 'BUY' && (
                        <span className="font-normal flex flex-col lg:flex-row  gap-2  ">
                          <span className=" dark:text-green-500 text-green-600">
                            {this.props.tx.status != 'COMPLETED'
                              ? 'Purchasing'
                              : 'Purchased'}
                          </span>{' '}
                          for {(this.props.tx.price || 0) / 1_000_000} ADA
                        </span>
                      )}
                      {this.props.tx.action === 'CANCEL' && (
                        <span className="font-normal flex flex-col lg:flex-row  gap-2  dark:text-red-500 text-red-600 shadow-red-600/40 text-shadow">
                          {' '}
                          {this.props.tx.status != 'COMPLETED'
                            ? 'Delisting'
                            : 'Delisted'}
                        </span>
                      )}
                      {this.props.tx.action === 'OFFER' && (
                        <span className="font-normal tracking-wider flex gap-1  dark:text-yellow-500 text-orange-600">
                          <span className="font-normal">
                            {this.props.tx.status != 'COMPLETED'
                              ? 'Making offer'
                              : 'Offer'}
                          </span>{' '}
                        </span>
                      )}
                      {this.props.tx.action === 'OFFER-CANCEL' && (
                        <span className="font-normal tracking-wider flex gap-1  dark:text-red-500 text-red-600">
                          <span className="font-normal">
                            {this.props.tx.status != 'COMPLETED'
                              ? 'Cancelling offer'
                              : 'Offer Cancelled'}
                          </span>{' '}
                        </span>
                      )}
                      {this.props.tx.action === 'OFFER-ACCEPTED' && (
                        <span className="font-normal tracking-wider flex gap-1  dark:text-green-500 text-green-600">
                          <span className="font-normal">
                            {this.props.tx.status != 'COMPLETED'
                              ? 'Accepting offer'
                              : 'Offer Accepted'}
                          </span>{' '}
                        </span>
                      )}
                      {this.props.tx.action === 'ACCEPT-OFFER' && (
                        <span className="font-normal tracking-wider flex gap-1  dark:text-green-500 text-green-600">
                          <span className="font-normal">
                            {this.props.tx.status != 'COMPLETED'
                              ? 'Accepting offer'
                              : 'Accepted Offer'}
                          </span>{' '}
                        </span>
                      )}
                      {this.props.tx.action === 'LIST' && (
                        <span className="font-normal flex flex-col lg:flex-row  gap-2 ">
                          <span className="dark:text-blue-500 text-blue-600  shadow-blue-600/40 text-shadow">
                            {' '}
                            {this.props.tx.status != 'COMPLETED'
                              ? 'Listing'
                              : 'Listed'}
                          </span>{' '}
                          for {(this.props.tx.price || 0) / 1_000_000} ADA
                        </span>
                      )}
                      {this.props.tx.action === 'RELIST' && (
                        <span className="font-normal flex flex-col lg:flex-row  gap-2  ">
                          <span className="dark:text-sky-500 text-sky-600  shadow-sky-600/50 text-shadow">
                            {' '}
                            {this.props.tx.status != 'COMPLETED'
                              ? 'Repricing'
                              : 'Repriced'}
                          </span>{' '}
                          for {(this.props.tx.price || 0) / 1_000_000} ADA
                        </span>
                      )}
                      {this.props.tx.action === 'MINT' && (
                        <span className="font-normal flex flex-col lg:flex-row  gap-2  ">
                          <span className="dark:text-green-500 text-green-600  shadow-green-500 text-shadow">
                            {' '}
                            {this.props.tx.status != 'COMPLETED'
                              ? 'Minting'
                              : 'Minted'}
                          </span>{' '}
                          for {(this.props.tx.price || 0) / 1_000_000} ADA
                        </span>
                      )}
                    </div>
                  )}
                </span>
              </Tooltip>
            </div>

            <div className=" flex flex-col items-end">
              <Tooltip
                content={dayjs(this.props.tx.createdDate).format(
                  'HH:mm a |  DD-MMM-YYYY'
                )}
              >
                <span className="text-base">
                  {dayjs(this.props.tx.createdDate).fromNow()}
                </span>
              </Tooltip>
              {this.props.tx.status === 'COMPLETED' ? (
                <>
                  <TxDisplay txHash={this.props.tx.txId} widthClass="w-24" />
                </>
              ) : (
                <Tooltip content={`View on  Cardanoscan`}>
                  <span className="flex gap-2 items-center text-sm">
                    <ArrowPathIcon className="h-5 w-5 text-sky-500 animate-spin" />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        </td>
      </tr>
    ) : (
      <tr className="w-full">
        <td className="whitespace-nowrap px-3 py-4 text-gray-500 dark:text-gray-200">
          {this.props.tx.action == 'OFFER' ||
          this.props.tx.action == 'OFFER-ACCEPTED' ||
          this.props.tx.action == 'ACCEPT-OFFER' ||
          this.props.tx.action == 'OFFER-CANCEL' ? (
            <>
              <div className="sack">
                <OfferDetails
                  trackedTransaction={this.props.tx}
                  compact={this.props.compact}
                />
              </div>
              {/*  */}
            </>
          ) : (
            <>
              <span className="flex flex-row gap-4 items-center ">
                <img
                  src={this.props.tx.asset?.thumbnailUrl}
                  alt={'xx'}
                  className="w-16 h-16 rounded-lg shadow-md object-cover"
                />
                <div className="flex flex-col">
                  <Link href={`/nft/${this.props.tx.asset?.id}`}>
                    <a className="font-normal text-sm lg:text-base flex flex-row gap-1 lg:gap-2">
                      <VerifiedCollectionPopoverSmall isVerified={true} />
                      {this.props.tx.asset?.algoliaProjectFacet &&
                        JSON.parse(this.props.tx.asset?.algoliaProjectFacet)
                          .title}
                    </a>
                  </Link>
                  <Link href={`/nft/${this.props.tx.asset?.id}`}>
                    <a className="font-bold  text-xs lg:text-sm">
                      {this.props.tx.asset?.title}
                    </a>
                  </Link>
                </div>
              </span>
            </>
          )}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-gray-500 dark:text-gray-200 text-base font-normal flex-col">
          {this.props.tx.action === 'SOLD' && (
            <span className="font-normal flex flex-col lg:flex-row gap-2  ">
              <span className=" dark:text-green-500 text-green-600">
                {this.props.tx.status != 'COMPLETED' ? 'Being sold' : 'Sold'}
              </span>{' '}
              for {(this.props.tx.price || 0) / 1_000_000} ADA
            </span>
          )}
          {this.props.tx.action === 'BUY' && (
            <span className="font-normal flex flex-col lg:flex-row  gap-2  ">
              <span className=" dark:text-green-500 text-green-600">
                {this.props.tx.status != 'COMPLETED'
                  ? 'Purchasing'
                  : 'Purchased'}
              </span>{' '}
              for {(this.props.tx.price || 0) / 1_000_000} ADA
            </span>
          )}
          {this.props.tx.action === 'CANCEL' && (
            <span className="font-normal flex flex-col lg:flex-row  gap-2  dark:text-red-500 text-red-600 shadow-red-600/40 text-shadow">
              {' '}
              {this.props.tx.status != 'COMPLETED' ? 'Delisting' : 'Delisted'}
            </span>
          )}
          {this.props.tx.action === 'OFFER' && (
            <span className="font-normal tracking-wider flex gap-1  dark:text-yellow-500 text-orange-600">
              <span className="font-normal">
                {this.props.tx.status != 'COMPLETED' ? 'Making offer' : 'Offer'}
              </span>{' '}
            </span>
          )}
          {this.props.tx.action === 'OFFER-CANCEL' && (
            <span className="font-normal tracking-wider flex gap-1  dark:text-red-500 text-red-600">
              <span className="font-normal">
                {this.props.tx.status != 'COMPLETED'
                  ? 'Cancelling offer'
                  : 'Offer Cancelled'}
              </span>{' '}
            </span>
          )}
          {this.props.tx.action === 'OFFER-ACCEPTED' && (
            <span className="font-normal tracking-wider flex gap-1  dark:text-green-500 text-green-600">
              <span className="font-normal">
                {this.props.tx.status != 'COMPLETED'
                  ? 'Accepting offer'
                  : 'Offer Accepted'}
              </span>{' '}
            </span>
          )}
          {this.props.tx.action === 'ACCEPT-OFFER' && (
            <span className="font-normal tracking-wider flex gap-1  dark:text-green-500 text-green-600">
              <span className="font-normal">
                {this.props.tx.status != 'COMPLETED'
                  ? 'Accepting offer'
                  : 'Accepted Offer'}
              </span>{' '}
            </span>
          )}
          {this.props.tx.action === 'LIST' && (
            <span className="font-normal flex flex-col lg:flex-row  gap-2 ">
              <span className="dark:text-blue-500 text-blue-600  shadow-blue-600/40 text-shadow">
                {' '}
                {this.props.tx.status != 'COMPLETED' ? 'Listing' : 'Listed'}
              </span>{' '}
              for {(this.props.tx.price || 0) / 1_000_000} ADA
            </span>
          )}
          {this.props.tx.action === 'RELIST' && (
            <span className="font-normal flex flex-col lg:flex-row  gap-2  ">
              <span className="dark:text-sky-500 text-sky-600  shadow-sky-600/50 text-shadow">
                {' '}
                {this.props.tx.status != 'COMPLETED' ? 'Repricing' : 'Repriced'}
              </span>{' '}
              for {(this.props.tx.price || 0) / 1_000_000} ADA
            </span>
          )}
          {this.props.tx.action === 'MINT' && (
            <span className="font-normal flex flex-col lg:flex-row  gap-2  ">
              <span className="dark:text-green-500 text-green-600  shadow-green-500 text-shadow">
                {' '}
                {this.props.tx.status != 'COMPLETED' ? 'Minting' : 'Minted'}
              </span>{' '}
              for {(this.props.tx.price || 0) / 1_000_000} ADA
            </span>
          )}
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-gray-500 dark:text-gray-400 fornt-normal">
          <Tooltip
            content={dayjs(this.props.tx.createdDate).format(
              'HH:mm a |  DD-MMM-YYYY'
            )}
          >
            <span className="text-base">
              {dayjs(this.props.tx.createdDate).fromNow()}
            </span>
          </Tooltip>
        </td>

        <td className="whitespace-nowrap px-3 py-4 text-gray-500 dark:text-gray-200 w-80">
          {this.props.tx.status === 'COMPLETED' ? (
            <div className="w-">
              <TxDisplay txHash={this.props.tx.txId} />
              {/* 
              <TxInfoModal txHash={this.props.tx.txId} /> */}
            </div>
          ) : (
            <span className="flex gap-2 items-center">
              <ArrowPathIcon className="h-5 w-5 text-sky-500 animate-spin" />
              Confirming
            </span>
          )}
        </td>
        {/*
        <td>
          Confirmation Status: {this.state.status} - Depth:{' '}
          {this.state.txnResult?.no_confirmations || '-'}
        </td> */}
      </tr>
    );
  }
}

type OfferDetailsProps = {
  trackedTransaction: TrackedTransaction;
  compact: boolean;
};

const OfferDetails = ({ trackedTransaction, compact }: OfferDetailsProps) => {
  const offerDetails =
    trackedTransaction.action == 'OFFER'
      ? trackedTransaction.offer
      : trackedTransaction.offer.details;

  return (
    <>
      {Array.isArray(offerDetails) &&
        offerDetails.map((item, idx) => (
          <div className="flex-col flex" key={`${item.policy}${idx}`}>
            <div className="flex gap-3 items-center">
              {item.type == 'Collection' && (
                <>
                  <div
                    className={cn('relative ', {
                      'w-12 h-12': compact,
                      'w-16 h-16': !compact,
                    })}
                  >
                    <NextImage
                      src={item.project.heroImage}
                      b64={item.project.b64}
                      priority={false}
                      width={100}
                      layout="fill"
                      className="rounded-xl "
                    />
                  </div>
                  <div className="flex flex-col text-sm  max-w-[128px] w-[128px] sm:w-[168px] sm:max-w-[168px] overflow-elipses">
                    <span className="font-medium text-base">
                      {' '}
                      ₳{item.offer / 1_000_000}
                    </span>
                    <span className="text-secondary md:flex hidden">
                      Collection Offer
                    </span>
                    {item.type == 'Collection' && (
                      <span className="whitespace-nowrap truncate md:block  hidden w-full ">
                        {item.project.name}
                      </span>
                    )}
                  </div>
                </>
              )}
              {item.type == 'Asset' && (
                <>
                  <div
                    className={cn('relative ', {
                      'w-12 h-12': compact,
                      'w-16 h-16': !compact,
                    })}
                  >
                    <AssetImage asset={item.asset as Asset} thumb={true} />
                  </div>

                  <div className="flex flex-col text-sm">
                    <span>₳{item.offer / 1_000_000}</span>
                    <div className="flex-col hidden md:flex">
                      <div className="text-accent">Asset Offer</div>

                      <span>{item.asset?.title}</span>
                      {/* <span className="font-bold">
                        {JSON.parse(item.asset?.algoliaProjectFacet).title}
                      </span> */}
                    </div>
                  </div>
                </>
              )}
            </div>
            {item.type == 'Collection' && (
              <span className="truncate w-full max-w-[128px] sm:max-w-[198px] md:hidden">
                {item.project.name}
              </span>
            )}
            {item.type == 'Collection' && (
              <span className="truncate w-full max-w-[128px] sm:max-w-[198px] md:hidden">
                <span className="text-secondary">Collection Offer</span>
              </span>
            )}
            {item.type == 'Asset' && (
              <div className="flex-col flex md:hidden">
                <span>{item.asset?.title}</span>
                <div className="text-accent">Asset Offer</div>
              </div>
            )}
          </div>
        ))}
    </>
  );
};
